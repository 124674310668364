var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-row',[_c('v-img',{attrs:{"src":require('./assets/banner.jpg'),"height":90,"gradient":"to right, rgba(0,0,0,1), rgba(0,0,0,.8)"}},[_c('v-row',{attrs:{"fill-height":""}},[_c('v-col',{staticClass:"mx-5",attrs:{"align":"start","justify":"start"}},[_c('h1',{staticClass:"white--text mb-n5",style:({ opacity: 1, fontSize: '50px' })},[_vm._v(" Ari Bennett ")]),_c('h2',{staticClass:"white--text mt-n5",style:({ opacity: 1 })},[_vm._v(" Design Portfolio ")])])],1)],1)],1),_c('v-row',[_c('Item',{attrs:{"src":[
        require('./assets/img/2_overshoot/1_os.jpg'),
        require('./assets/img/2_overshoot/2_os.jpg'),
        require('./assets/img/2_overshoot/3_os.gif') ],"interval":5,"desc":"Overshoot Casting Study"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/1_resharp/1_rs.jpg'),
        require('./assets/img/1_resharp/2_rs.jpg'),
        require('./assets/img/1_resharp/3_rs.png') ],"desc":"Resharp Knife Sharpening","interval":3}}),_c('Item',{attrs:{"src":[
        require('./assets/img/5_momentum/1_mt.jpg'),
        require('./assets/img/5_momentum/2_mt.gif') ],"desc":"Momentum Brushless Gyroscope"}})],1),_c('v-row',[_c('Item',{attrs:{"src":[
        require('./assets/img/3_tesselite/1_ts.jpg'),
        require('./assets/img/3_tesselite/2_ts.jpg'),
        require('./assets/img/3_tesselite/3_ts.jpg') ],"desc":"Tesselight Light Tiles"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/7_ked/1_kd.jpg'),
        require('./assets/img/7_ked/2_kd.jpg') ],"desc":"Knee Enhancement Device"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/4_balance/1_bal.gif'),
        require('./assets/img/4_balance/2_bal.jpg') ],"interval":10,"desc":"Balance Inverted Pendulum"}})],1),_c('v-row',[_c('Item',{attrs:{"src":[
        require('./assets/img/8_laser/1_ls.jpeg'),
        require('./assets/img/8_laser/2_ls.gif') ],"interval":10,"desc":"Desktop Laser Cutter"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/9_iron/1_ir.jpg'),
        require('./assets/img/9_iron/2_ir.jpg') ],"desc":"Custom Soldering Iron"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/6_lego/1_lg.jpg'),
        require('./assets/img/6_lego/2_lg.jpg'),
        require('./assets/img/6_lego/3_lg.jpg'),
        require('./assets/img/6_lego/4_lg.jpg'),
        require('./assets/img/6_lego/5_lg.jpg'),
        require('./assets/img/6_lego/6_lg.jpg'),
        require('./assets/img/6_lego/7_lg.jpg'),
        require('./assets/img/6_lego/8_lg.jpg'),
        require('./assets/img/6_lego/9_lg.jpg'),
        require('./assets/img/6_lego/10_lg.jpg'),
        require('./assets/img/6_lego/11_lg.jpg'),
        require('./assets/img/6_lego/12_lg.jpg'),
        require('./assets/img/6_lego/13_lg.jpg'),
        require('./assets/img/6_lego/14_lg.jpg'),
        require('./assets/img/6_lego/15_lg.jpg'),
        require('./assets/img/6_lego/16_lg.jpg'),
        require('./assets/img/6_lego/17_lg.jpg'),
        require('./assets/img/6_lego/18_lg.jpg') ],"desc":"LEGO Machine Shop","interval":1.5}})],1),_c('v-row',[_c('Item',{attrs:{"src":[
        require('./assets/img/10_kiwi/1_ki.jpg'),
        require('./assets/img/10_kiwi/2_ki.jpg') ],"desc":"Kiwi Keyless Entry"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/11_tt/2_tt.jpg'),
        require('./assets/img/11_tt/3_tt.png'),
        require('./assets/img/11_tt/1_tt.jpg') ],"desc":"Touch Torch Flashlight"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/12_218/1_218.jpg'),
        require('./assets/img/12_218/2_218.jpg') ],"desc":"Graduate Mechatronics"}})],1),_c('v-row',[_c('Item',{attrs:{"src":[require('./assets/img/13_orb/1_orb.jpg')],"desc":"3D Printed Lighting"}}),_c('Item',{attrs:{"src":[require('./assets/img/14_silicone/1_si.jpg')],"desc":"Candy Molds"}}),_c('Item',{attrs:{"src":[
        require('./assets/img/15_way/1_way.jpg'),
        require('./assets/img/15_way/2_way.jpg') ],"desc":"Digital Wayfinder"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }